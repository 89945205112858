<style lang="scss" scoped>
.header-wrap {
  height: 64px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 #00000014;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header-title {
    width: 282px;
    height: 42px;
    line-height: 42px;
    font-weight: bold;
    img {
      // width: 100%;
      height: 100%;
    }
  }
  .header-operator-wrap {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    .operator-content-wrap {
      display: flex;
      .operator-item {
        font-weight: 400;
        font-size: 14px;
        color: #191f3f;
        margin-right: 24px;
        cursor: pointer;
        img {
          margin-right: 8px;
        }
      }
    }
  }
}
.drop-name {
  font-weight: 400;
  font-size: 16px;
  color: #191f3f;
  margin-left: 6px;
}
</style>
<template>
  <div class="header-wrap">
    <div class="header-title">
      <!-- <img src="../../../assets/images/logo.png" alt="" /> -->
      后台管理系统
      <i
        style="color: #409eff;cursor: pointer;"
        v-if="collapsed"
        @click="changeSide"
        class="el-icon-s-fold"
      ></i>
      <i
        style="color: #409eff;cursor: pointer;"
        v-if="!collapsed"
        @click="changeSide"
        class="el-icon-s-unfold"
      ></i>
    </div>
    <div class="header-operator-wrap">
      <div style="margin-right: 20px">代理商余额还剩：{{ accountAmount }}₱</div>
      <div class="operator-content-wrap">
        <el-dropdown trigger="click" @command="handleCommand">
          <div class="operator-item">
            <img src="../../../assets/images/headerBar/admin.png" alt="" />
            {{ userName }} <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="password">
              <span class="drop-name">更新密码</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout">
              <span class="drop-name">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <div class="operator-item">
          <el-dropdown trigger="click" @command="changeLanguage">
            <div>
              {{ showLang }}<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh" :disabled="'zh' === $i18n.locale"
                >中文</el-dropdown-item
              >
              <el-dropdown-item command="en" :disabled="'en' === $i18n.locale"
                >English</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
      </div>
    </div>
    <el-dialog
      title="更新密码"
      :visible.sync="dialogVisible"
      width="750px"
      :before-close="handleClose1"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <div class="dialog-user-wrap dialog-add-wrap">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item label="老密码" prop="oldPassword">
            <el-input
              v-model="ruleForm.oldPassword"
              placeholder="请输入"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              v-model="ruleForm.newPassword"
              placeholder="请输入"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="passwordAgain">
            <el-input
              v-model="ruleForm.passwordAgain"
              placeholder="请输入"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitEvent">提交</el-button>
        <el-button @click="handleClose1">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "headerBar",
  data() {
    return {
      accountAmount: "",
      dialogVisible: false,
      userName: "",
      showLang: "中文",
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        passwordAgain: "",
        userID: "",
      },
      rules: {
        oldPassword: [{ required: true, message: "请输入", trigger: "blur" }],
        newPassword: [{ required: true, message: "请输入", trigger: "blur" }],
        passwordAgain: [
          { required: true, message: "请输入", trigger: "blur" },
          {
            validator: (rules, value, callback) => {
              if (value !== this.ruleForm.newPassword)
                callback(new Error("确认密码与密码不一致"));
              callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    collapsed: {
      default: true,
    },
  },
  mounted() {
    this.userName = localStorage.getItem("username");
    this.getAcount();
  },
  methods: {
    changeSide() {
      if (this.collapsed) {
        this.$emit("open", false);
      } else {
        this.$emit("open", true);
      }
    },
    getAcount() {
      return new Promise((resolive, reject) => {
        axios
          .request({
            url: `/common/recharge/getBalance`,
            method: "post",
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              this.accountAmount = res?.data?.accountAmount;
            }
            resolive(res);
          });
      });
    },
    submitEvent() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.userID = localStorage.getItem("userID");
          let data = {
            oldPassword: this.ruleForm.oldPassword,
            newPassword: this.ruleForm.newPassword,
            userId: this.ruleForm.userID,
          };
          let method = "post";

          return new Promise((resolive, reject) => {
            axios
              .request({
                url: `/user/updatePassword`,
                method,
                data: data,
                config: {
                  isLoading: true,
                },
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message.success("成功");
                  this.handleClose1();
                  localStorage.removeItem('token');
                  this.$router.replace('login')
                } else {
                }
                resolive(res);
              });
          });
        } else {
          return false;
        }
      });
    },
    handleClose1() {
      this.$refs["ruleForm"].resetFields();
      this.dialogVisible = false;
    },
    changeLanguage(lang) {
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang; // 设置给本地的 i18n 插件
      this.$message.success("切换多语言成功");
      this.showLang = lang == "zh" ? "中文" : "English";
    },
    handleCommand(command) {
      switch (command) {
        case "password":
          this.dialogVisible = true;
          break;
        case "logout":
          Cookies.remove("token");
          localStorage.removeItem("username");
          this.$router.push("/login");
          break;
        default:
          break;
      }
    },
  },
};
</script>

import menuDataJson from "./user.json";
export default {
  namespaced: true,
  state: {
    menuList: menuDataJson || [],
  },
  mutations: {
    setMenuList(state, menuList) {
      state.menuList = menuList;
    },
  },
  actions: {},
};

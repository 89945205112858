<style lang="scss" scoped>
.zzuiTable {
  ::v-deep .el-table {
    thead {
      th.el-table__cell {
        background: #00000005;
        font-weight: 500;
        font-size: 14px;
        color: #000000d9;
        // text-align: center;
      }
    }
    .el-table__body-wrapper {
      .el-table__cell {
        font-weight: 400;
        font-size: 14px;
        color: #191f3f;
        // text-align: center;
      }
    }
  }
  ::v-deep .page-wrap {
    text-align: right;
    margin-top: 16px;
    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      font-size: 14px;
      color: #000000a6;
      text-align: center;
    }
    .el-pagination.is-background .el-pager li {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      font-weight: 400;
      font-size: 14px;
      color: #000000a6;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #3263f9;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
    }
    .el-pagination.is-background .btn-next.disabled,
    .el-pagination.is-background .btn-next:disabled,
    .el-pagination.is-background .btn-prev.disabled,
    .el-pagination.is-background .btn-prev:disabled,
    .el-pagination.is-background .el-pager li.disabled {
      color: #c0c4cc;
      background-color: #f4f4f5;
    }
  }
}
</style>

<template>
  <div class="zzuiTable">
    <!-- 使用 v-bind和v-on来传递属性和方法-->
    <el-table v-bind="$attrs" v-on="$listeners" :data="data">
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        v-bind="item"
      >
        <template v-if="item.render" v-slot="scope">
          <!-- 使用函数式组件进行dom渲染 -->
          <!-- <render-dom
            :render="(c, h) => item.render(scope.row, c, h)"
          ></render-dom> -->
          <tableCell
            :row="scope.row"
            :column="item"
            :scope-column="scope.column"
            :index="scope.$index"
            :render="item.render"
            :columns="columns"
            :data="data"
          ></tableCell>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-wrap">
      <el-pagination
        v-if="isPage"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageNum"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        v-bind="$attrs"
        layout="total,  prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import tableCell from "./tableCell";
export default {
  name: "private-table",

  components: {
    tableCell,
    // 函数式组件注册
    renderDom: {
      functional: true,
      props: {
        render: Function,
      },
      render(createElement, renDom) {
        console.log(createElement);
        console.log(renDom);
        return <div>{renDom.props.render()}</div>;
      },
    },
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    pagination: Object,
    isPage: {
      type: Boolean,
      default: true,
    },
  },
  watch: {},
  mounted() {
    if (this.pagination && !this.pagination.showPageSize) {
      this.layout = "total, prev, pager, next";
    }
  },
  data() {
    return {
      layout: "total, sizes, prev, pager, next",
    };
  },
  methods: {
    // pageSize 改变时会触发
    handleSizeChange(args) {
      this.$emit("size-change", args);
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.$emit("page-change", val);
    },
  },
};
</script>

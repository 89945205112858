import {
  getBreadCrumbList,
  getMenuByRouter,
  getOnlyOneMenuByRouter,
  getHomeRoute,
} from "@/libs/util";
import routers from "@/router/routers";

export default {
  state: {
    breadCrumbList: [],
    homeRoute: getHomeRoute(routers),
    local: "",
  },
  getters: {
    menuList: (state, getters, rootState) =>
      getMenuByRouter(rootState.user.menu, rootState.user.access),
    becomeOnlyOneList: (state, getters, rootState) =>
      getOnlyOneMenuByRouter(rootState.user.menu, rootState.user.access),
  },
  mutations: {
    setBreadCrumb(state, routeMetched) {
      state.breadCrumbList = getBreadCrumbList(routeMetched, state.homeRoute);
    },
  },
};

import Vue from "vue";
import Router from "vue-router";
import routes from "./routers";

export const asyncRoutes = [];

Vue.use(Router);
const router = new Router({
  routes,
  mode: "history",
  // base: "prophet",
});

const LOGIN_PAGE_NAME = "login";
//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach((to) => {
  window.scrollTo(0, 0);
});

export default router;

import Main from "@/view/main/main.vue";

/**
 * meta除了原生参数外可配置的参数:
 * meta: {
 *  hideInMenu: (false) 设为true后在左侧菜单不会显示该页面选项
 *  notCache: (false) 设为true后页面不会缓存
 *  access: (null) 可访问该页面的权限数组，当前路由设置的权限会影响子路由
 *  icon: (-) 该页面在左侧菜单、面包屑和标签导航处显示的图标，如果是自定义图标，需要在图标名称前加下划线'_'
 *  LevelOneHeading 一级标题
 *  hasReturn参数为true表示此页面左上角有返回按钮，返回上一页
 *  topical    属于哪个路由下的
 * }
 */

export default [
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Login - 登录",
      hideInMenu: true,
      topical: "login",
    },
    component: () => import("@/view/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      title: "注册",
      hideInMenu: true,
      topical: "register",
    },
    component: () => import("@/view/login/register.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    meta: {
      title: "忘记密码",
      hideInMenu: true,
      topical: "forgotPassword",
    },
    component: () => import("@/view/login/forgotPassword.vue"),
  },
  {
    path: "/",
    name: "_home",
    redirect: "/home",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "home",
    },
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "入场助手",
          hideInMenu: true,
          topical: "home",
          LevelOneHeading: "入场助手",
        },
        component: () => import("@/view/home/index.vue"),
        children: [],
      },
    ],
  },
  {
    path: "/manageUser",
    name: "manageUser",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "manageUser",
    },
    children: [
      {
        path: "/manageUserList",
        name: "manageUserList",
        meta: {
          title: "账户管理",
          hideInMenu: true,
          topical: "manageUserList",
          LevelOneHeading: "账户管理",
        },
        component: () => import("@/view/adminUser/index.vue"),
        children: [],
      },
    ],
  },
  {
    path: "/rollingMessageManagement",
    name: "rollingMessageManagement",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "rollingMessageManagement",
    },
    children: [
      {
        path: "/rollingMessageManagementList",
        name: "rollingMessageManagementList",
        meta: {
          title: "滚动消息管理",
          hideInMenu: true,
          topical: "rollingMessageManagementList",
          LevelOneHeading: "滚动消息管理",
        },
        component: () =>
          import(
            "@/view/rollingMessageManagement/rollingMessageManagementList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/rotationChartManagement",
    name: "rotationChartManagement",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "rotationChartManagement",
    },
    children: [
      {
        path: "/rotationChartManagementList",
        name: "rotationChartManagementList",
        meta: {
          title: "轮播图管理",
          hideInMenu: true,
          topical: "rotationChartManagementList",
          LevelOneHeading: "轮播图管理",
        },
        component: () =>
          import(
            "@/view/rotationChartManagement/rotationChartManagementList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/advertisingManagement",
    name: "advertisingManagement",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "advertisingManagement",
    },
    children: [
      {
        path: "/advertisingManagementList",
        name: "advertisingManagementList",
        meta: {
          title: "广告管理",
          hideInMenu: true,
          topical: "advertisingManagementList",
          LevelOneHeading: "广告管理",
        },
        component: () =>
          import("@/view/advertisingManagement/advertisingManagementList.vue"),
        children: [],
      },
    ],
  },
  {
    path: "/rechargeRecordManagement",
    name: "rechargeRecordManagement",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "rechargeRecordManagement",
    },
    children: [
      {
        path: "/rechargeRecordManagementList",
        name: "rechargeRecordManagementList",
        meta: {
          title: "充值记录管理",
          hideInMenu: true,
          topical: "rechargeRecordManagementList",
          LevelOneHeading: "充值记录管理",
        },
        component: () =>
          import(
            "@/view/rechargeRecordManagement/rechargeRecordManagementList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/depositRecordManagement",
    name: "depositRecordManagement",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "depositRecordManagement",
    },
    children: [
      {
        path: "/depositRecordManagementList",
        name: "depositRecordManagementList",
        meta: {
          title: "充值记录管理",
          hideInMenu: true,
          topical: "depositRecordManagementList",
          LevelOneHeading: "充值记录管理",
        },
        component: () =>
          import(
            "@/view/depositRecordManagement/depositRecordManagementList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/withdrawalRecordManagement",
    name: "withdrawalRecordManagement",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "withdrawalRecordManagement",
    },
    children: [
      {
        path: "/withdrawalRecordManagementList",
        name: "withdrawalRecordManagementList",
        meta: {
          title: "提现记录管理",
          hideInMenu: true,
          topical: "withdrawalRecordManagementList",
          LevelOneHeading: "提现记录管理",
        },
        component: () =>
          import(
            "@/view/withdrawalRecordManagement/withdrawalRecordManagementList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/customerServiceConfiguration",
    name: "customerServiceConfiguration",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "customerServiceConfiguration",
    },
    children: [
      {
        path: "/customerServiceConfigurationList",
        name: "customerServiceConfigurationList",
        meta: {
          title: "客服配置",
          hideInMenu: true,
          topical: "customerServiceConfigurationList",
          LevelOneHeading: "客服配置",
        },
        component: () =>
          import(
            "@/view/customerServiceConfiguration/customerServiceConfigurationList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/depositItemConfiguration",
    name: "depositItemConfiguration",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "depositItemConfiguration",
    },
    children: [
      {
        path: "/depositItemConfigurationList",
        name: "depositItemConfigurationList",
        meta: {
          title: "存款项配置",
          hideInMenu: true,
          topical: "depositItemConfigurationList",
          LevelOneHeading: "存款项配置",
        },
        component: () =>
          import(
            "@/view/depositItemConfiguration/depositItemConfigurationList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/withdrawalMange",
    name: "withdrawalMange",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "withdrawalMange",
    },
    children: [
      {
        path: "/withdrawalMangeList",
        name: "withdrawalMangeList",
        meta: {
          title: "提现项配置",
          hideInMenu: true,
          topical: "withdrawalMangeList",
          LevelOneHeading: "提现项配置",
        },
        component: () => import("@/view/withdrawalMange/index.vue"),
        children: [],
      },
    ],
  },
  {
    path: "/rechargeItemConfiguration",
    name: "rechargeItemConfiguration",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "rechargeItemConfiguration",
    },
    children: [
      {
        path: "/rechargeItemConfigurationList",
        name: "rechargeItemConfigurationList",
        meta: {
          title: "汇率配置",
          hideInMenu: true,
          topical: "rechargeItemConfigurationList",
          LevelOneHeading: "汇率配置",
        },
        component: () =>
          import(
            "@/view/rechargeItemConfiguration/rechargeItemConfigurationList.vue"
          ),
        children: [],
      },
    ],
  },
  {
    path: "/rechargeRate",
    name: "rechargeRate",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "rechargeRate",
    },
    children: [
      {
        path: "/rechargeRateConfig",
        name: "rechargeRateConfig",
        meta: {
          title: "存款/提现比例配置",
          hideInMenu: true,
          topical: "rechargeRateConfig",
          LevelOneHeading: "存款/提现比例配置",
        },
        component: () => import("@/view/rechargeRate/index.vue"),
        children: [],
      },
    ],
  },
  {
    path: "/recharge",
    name: "recharge",
    component: Main,
    meta: {
      hideInMenu: true,
      topical: "recharge",
    },
    children: [
      {
        path: "/rechargeManage",
        name: "rechargeManage",
        meta: {
          title: "话费/流量充值配置",
          hideInMenu: true,
          topical: "rechargeManage",
          LevelOneHeading: "话费/流量充值配置",
        },
        component: () => import("@/view/rechargeManage/index.vue"),
        children: [],
      },
    ],
  },
];

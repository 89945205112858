<style lang="scss" scoped>
.side-menu-wrap {
  background: #ffffff;
  border-right: 1px solid #d9d9d9;
  //   padding: 0 16px;
  padding-left: 16px;
  padding-top: 14px;
  .side-item-wrap {
    .sides-wrap {
      margin-bottom: 8px;

      .deg {
        margin-top: 8px;
        .deg-content {
          width: 100%;
          height: 1px;
          background: #d9d9d9;
        }
      }
      .side-title {
        // padding: 0 8px;
        padding-left: 8px;
        height: 32px;
        line-height: 32px;
        font-weight: 400;
        font-size: 12px;
        color: #00000073;
      }
      .side-content-wrap {
        .side-content-item {
          height: 40px;
          line-height: 40px;
          //   padding: 0 8px;
          padding-left: 8px;
          font-weight: 400;
          font-size: 16px;
          color: #191f3f;
          cursor: pointer;
          img {
            margin-right: 6px;
          }
          &.activiteSide {
            background: #3263f914;
            border-radius: 6px 0px 0px 6px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="side-menu-wrap" :style="{ width: collapsed ? '180px' : '20px' }">
    <transition name="el-fade-in-linear">
      <div class="side-menu-content" v-if="collapsed">
        <div
          class="side-item-wrap"
          v-for="(item, index) of menuList"
          :key="index"
        >
          <template v-if="item.children.length">
            <div class="sides-wrap">
              <div class="side-title">{{ item.meta.title }}</div>
              <div class="side-content-wrap">
                <div
                  class="side-content-item"
                  v-for="(child, i) of item.children"
                  :key="i"
                  @click="navClick(item, child)"
                  :class="child.name == activeName ? 'activiteSide' : ''"
                >
                  <!-- <img
                    :src="
                      require(`../../../assets/images/sideMenu/${child.meta.icon}.png`)
                    "
                    alt=""
                  /> -->
                  <!-- {{ child.meta.title }} -->
                  {{ routeTitle(child) }}
                </div>
              </div>
              <div class="deg">
                <div class="deg-content"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="sides-wrap">
              <div class="side-content-wrap">
                <div
                  class="side-content-item"
                  @click="navClick(item)"
                  :class="item.name == activeName ? 'activiteSide' : ''"
                >
                  <!-- <img
                    :src="
                      require(`../../../assets/images/sideMenu/${item.meta.icon}.png`)
                    "
                    alt=""
                  /> -->
                  <!-- {{ item.meta.title }} -->
                  {{ routeTitle(item) }}
                </div>
              </div>
              <div class="deg">
                <div class="deg-content"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="side-menu-content" v-else>
        <div
          class="side-item-wrap"
          v-for="(item, index) of menuList"
          :key="index"
        >
          <template v-if="item.children.length">
            <div class="sides-wrap">
              <div class="side-content-wrap">
                <div
                  class="side-content-item"
                  v-for="(child, i) of item.children"
                  :key="i"
                  @click="navClick(item, child)"
                  :class="child.name == activeName ? 'activiteSide' : ''"
                  :style="{ 'text-align': collapsed ? 'left' : 'center' }"
                >
                  <!-- <img
                    :src="
                      require(`../../../assets/images/sideMenu/${child.meta.icon}.png`)
                    "
                    alt=""
                  /> -->
                </div>
              </div>
              <div
                class="deg"
                :style="{ 'padding-right': collapsed ? '24px' : '8px' }"
              >
                <div class="deg-content"></div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="sides-wrap">
              <div class="side-content-wrap">
                <div
                  class="side-content-item"
                  @click="navClick(item)"
                  :class="item.name == activeName ? 'activiteSide' : ''"
                  :style="{ 'text-align': collapsed ? 'left' : 'center' }"
                >
                  <!-- <img
                    :src="
                      require(`../../../assets/images/sideMenu/${item.meta.icon}.png`)
                    "
                    alt=""
                  /> -->
                </div>
              </div>
              <div class="deg">
                <div class="deg-content"></div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    activeName: "",
    collapsed: {
      default: true,
    },
  },
  name: "",
  data() {
    return {};
  },
  computed: {
    menuList() {
      return this.$store.state.user.menuList;
    },
  },
  watch: {
    activeName(nval) {},
  },
  mounted() {},
  methods: {
    routeTitle(item) {
      const name = `route.${item.name}`;
      if (this.$te(name)) {
        return this.$t(name);
      }
      return item.meta.title;
    },
    navClick(parent, child) {
      let name = "";
      if (child) {
        name = child.name;
      } else {
        name = parent.name;
      }

      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-wrap {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  .info {
    font-weight: 400;
    font-size: 14px;
    color: #191f3f;
    margin-top: 8px;
  }
}
</style>
<template>
  <el-dialog :title="title" :visible.sync="dialogVisibleTip" width="412px">
    <div class="dialog-user-wrap1">
      <div class="confirm-wrap">
        <div class="img-wrap">
          <!-- <img src="@/assets/images/certificate/bell.png" /> -->
        </div>
        <div class="info">{{ info }}</div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeTipEvent">取 消</el-button>
      <el-button type="primary" @click="submitEvent">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "tipModal",
  props: {
    value: {
      default: false,
      type: Boolean,
      required: true,
    },
    title: {
      default: "",
      type: String,
      required: true,
    },
    info: {
      default: "",
      type: String,
      required: true,
    },
    type: {
      default: "Warn",
      type: String,
      required: true,
    },
  },
  watch: {
    value(nval) {
      this.dialogVisibleTip = nval;
    },
  },
  data() {
    return {
      dialogVisibleTip: false,
    };
  },
  mounted() {},
  methods: {
    submitEvent() {
      this.$emit("tipConfirmEvent");
    },
    closeTipEvent() {
      this.$emit("closeTipEvent");
    },
  },
};
</script>

import Vue from "vue";
// vue-i18n所需要引入的
import VueI18n, { createI18n } from "vue-i18n";
// import VueI18n from "vue-i18n";
// 本地的文件夹
import enLocale from "./en_us";
import zhLocale from "./zh_cn";
import zhhkLocale from "./zh_hk";
// element ui 国际化
import Element from "element-ui";
import enUS from "element-ui/lib/locale/lang/en";
import zhCN from "element-ui/lib/locale/lang/zh-CN";
import zhHK from "element-ui/lib/locale/lang/zh-TW";
Vue.use(VueI18n);
const messages = {
  en: {
    ...enUS,
    ...enLocale,
  },
  zh: {
    ...zhCN,
    ...zhLocale,
  },
  zhhk: {
    ...zhHK,
    ...zhhkLocale,
  },
};

// 获取浏览器的语言
const lang = (navigator.language || "en").toLocaleLowerCase();
const language = localStorage.getItem("language") || lang.split("-")[0] || "zh";
localStorage.setItem("language", language);
// const i18n = createI18n({
//   //Not available in legacy mode 解决报错问题的配置项！！！
//   legacy: false,
//   // 全局注册 $t方法
//   globalInjection: true,
//   //设置初始化语言
//   locale: language,
//   // 设置备用语言
//   fallbackLocale: "en",
//   messages,
// });
const i18n = new VueI18n({
  locale: language, // 默认
  messages,
  legacy: false,
  silentTranslationWarn: true,
});
// vue配置
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value),
});

export { i18n };

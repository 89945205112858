export default {
  language: {
    name: "简体",
  },
  app: {
    hello: "你好，世界！",
  },
  // 路由国际化
  route: {
    home: "用户管理",
    rollingMessageManagementList: "滚动消息管理",
    rotationChartManagementList: "轮播图管理",
    advertisingManagementList: "广告管理",
    rechargeRecordManagementList: "充值记录管理",
    depositRecordManagementList: "存款记录管理",
    withdrawalRecordManagementList: "提现记录管理",
    customerServiceConfigurationList: "客服配置",
    depositItemConfigurationList: "存款项配置",
    withdrawalMangeList: "提现项配置",
    rechargeItemConfigurationList: "汇率配置",
    rechargeRateConfig: "存款/提现比例配置",
  },
};

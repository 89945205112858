import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "./my-theme/element-variables.scss";
import "@/assets/css/index.scss";
import router from "./router";
import store from "./store";
import install from "@/components";
import axios from "@/libs/api.request";

import * as echarts from "echarts";
import "echarts-liquidfill";
Vue.prototype.$echarts = echarts;

//引入上面配置好的语言文件

import { i18n } from "./lang";

Vue.use(install);
Vue.config.productionTip = false;
window.axios = axios;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

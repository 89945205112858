export default {
  language: {
    name: "English",
  },
  app: {
    hello: "Hello，World!",
  },
  // 路由国际化
  route: {
    home: "Home",
    rollingMessageManagementList: "RollingMessageManagement",
    rotationChartManagementList: "RotationChartManagement",
    advertisingManagementList: "AdvertisingManagement",
    rechargeRecordManagementList: "RechargeRecordManagement",
    depositRecordManagementList: "DepositRecordManagement",
    withdrawalRecordManagementList: "WithdrawalRecordManagement",
    customerServiceConfigurationList: "CustomerServiceConfiguration",
    depositItemConfigurationList: "DepositItemConfiguration",
    withdrawalMangeList: "withdrawalMangeList",
    rechargeItemConfigurationList: "RechargeItemConfiguration",
    rechargeRateConfig: "存款/提现比例配置",
  },
};

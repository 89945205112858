import Axios from "axios";
// import baseURL from '_conf/url'
import { Message, Loading } from "element-ui";
import Cookies from "js-cookie";
import router from "../router";
let loadingInstance = null;
class httpRequest {
  constructor() {
    this.options = {
      method: "",
      url: "",
    };
    // 存储请求队列
    this.queue = {};
  }

  // 销毁请求实例
  destroy(url) {
    delete this.queue[url];
    const queue = Object.keys(this.queue);
    return queue.length;
  }

  // 请求拦截
  interceptors(instance, url) {
    // 添加请求拦截器
    instance.interceptors.request.use(
      (config) => {
        console.log(config, "con");
        if (
          config.url.includes("/common/file/upload") ||
          config.url.includes("/common/recharge/getBalance")
        ) {
          config.baseURL = process.env.VUE_APP_APP_HOST + "";
        }
        config.headers.deviceType = "admin";
        const token = Cookies.get("token");
        if (token) {
          // jwt token
          config.headers.token = `${token}`;
        }

        // 在发送请求之前做些什么
        if (config.config && config.config.isLoading) {
          loadingInstance = Loading.service({
            lock: true,
            text: "",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.3)",
          });
        }

        return config;
      },
      (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
      }
    );

    // 添加响应拦截器
    instance.interceptors.response.use(
      (res) => {
        let { data } = res;
        const is = this.destroy(url);
        if (!is) {
          setTimeout(() => {
            if (loadingInstance) {
              loadingInstance.close();
            }
          });
        }
        if (res.config.url.includes("/download")) {
          return res;
        }
        if (data.code != 200) {
          // 后端服务在个别情况下回报201，待确认
          if (data.code === 401 || data.code === 403) {
            Message.closeAll();

            Cookies.remove("token");

            setTimeout(() => {
              router.push({ name: "login" });
            }, 1500);
          } else {
            if (data.message) {
              Message.closeAll();
              Message.error(data.message);
            }
          }
          return data;
        }
        return data;
      },
      (error) => {
        console.log(error, "error");
        let response = error.response;
        const is = this.destroy(url);
        if (!is) {
          setTimeout(() => {
            if (loadingInstance) {
              loadingInstance.close();
            }
          }, 200);
        }
        if (loadingInstance) {
          loadingInstance.close();
        }
        if (
          response.data?.code == 800 ||
          response.data?.code == 801 ||
          response.data?.code == 802
        ) {
          Cookies.remove("token");
          setTimeout(() => {
            router.push({ name: "login" });
          }, 1500);
        } else {
          Message.error("服务内部错误");
        }

        // 对响应错误做点什么
        // return Promise.reject(error);
        return error;
      }
    );
  }

  // 创建实例
  create() {
    let conf = {
      baseURL: process.env.VUE_APP_APP_HOST + "/admin",
      // baseURL: process.env.VUE_APP_APP_HOST,
      // timeout: 2000,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    };
    return Axios.create(conf);
  }

  // 合并请求实例
  mergeReqest(instances = []) {
    //
  }

  // 请求实例
  request(options) {
    // let data = {
    //     token: getToken()
    // }
    // if (options.method === 'get') {
    //     if (options.params) options.params = Object.assign(options.params, data)
    //     if (!options.params) options.params = Object.assign({}, data)
    // } else {
    //     if (options.data) options.data = Object.assign(options.data, data);
    //     if (!options.data) options.data = Object.assign({}, data);
    // }
    var instance = this.create();
    let url = options.url;
    this.interceptors(instance, url);
    options = Object.assign({}, options);
    this.queue[url] = instance;
    return instance.request(options);
  }
}

export default httpRequest;

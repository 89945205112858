import privateTable from "./table";
import tipModal from "./tipModal";
const components = [privateTable, tipModal];

const install = (Vue, opts = {}) => {
  components.map((component) => {
    Vue.component(component.name, component);
  });
};

export default install;

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .main-headerBar {
    position: sticky;
    width: 100%;
    z-index: 20;
    top: 0;
    height: 64px;
  }
  .left-slider-wrap {
    position: fixed;
    top: 74px;
    bottom: 0;
    z-index: 20;
    left: 0;
    overflow-y: auto;
  }
  .router-content-wrap {
    padding-left: 180px;
    position: relative;
    width: 100%;

    .router-container-wrap {
      position: relative;
      padding: 24px 48px;
      .collapsed-wrap {
        position: absolute;
        top: 25px;
        left: 0px;
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        border-radius: 0 6px 6px 0;
        cursor: pointer;
        text-align: center;
        padding: 4px;
        img {
          vertical-align: middle;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="main">
    <HeaderBar :collapsed="collapsed" @open='openSide' class="main-headerBar"></HeaderBar>

    <sideMenu
      class="left-slider-wrap"
      :activeName="activeName"
      :collapsed="collapsed"
    ></sideMenu>
    <div
      class="router-content-wrap"
      :style="{
        'padding-left': collapsed ? '180px' : '20px',
      }"
    >
      <div class="router-container-wrap">
        <!-- <div class="collapsed-wrap" @click="collapsedEvent">
          <img
            v-if="collapsed"
            src="../../assets/images/sideMenu/collapsed2.png"
            alt=""
          />
          <img
            v-else
            src="../../assets/images/sideMenu/collapsed1.png"
            alt=""
          />
        </div> -->
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBar from "./header-bar/index.vue";
import sideMenu from "./side-menu/index.vue";
import { mapMutations, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    HeaderBar,
    sideMenu,
  },
  data() {
    return {
      activeName: "",
      collapsed: true,
    };
  },
  watch: {
    $route(newRoute) {
      this.setBreadCrumb(newRoute.matched);
      this.activeName = newRoute.meta.topical;
    },
  },

  mounted() {
    this.activeName = this.$route.meta.topical;
  },
  methods: {
    ...mapMutations(["setBreadCrumb"]),
    collapsedEvent() {
      this.collapsed = !this.collapsed;
    },
    openSide(value){
      this.collapsed = value
    }
  },
};
</script>

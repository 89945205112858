// import _ from "lodash";
import menuJson from "@/store/module/user.json";

import { forEach, hasOneOf } from "@/libs/tools";

export const TOKEN_KEY = "token";

export const hasChild = (item) => {
  return item.children && item.children.length !== 0;
};

const showThisMenuEle = (item, access) => {
  if (item.meta && item.meta.access && item.meta.access.length) {
    if (hasOneOf(item.meta.access, access)) return true;
    else return false;
  } else return true;
};
/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array}
 */
export const getMenuByRouter = (list, access) => {
  let res = [];
  forEach(list, (item) => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu && item.isShow)) {
      // isShow是true的情况下
      let obj = {
        icon: (item.meta && item.meta.url) || "",
        name: item.name,
        meta: item.meta,
        isShow: item.isShow,
      };
      if (
        (hasChild(item) || (item.meta && item.meta.showAlways)) &&
        showThisMenuEle(item, access)
      ) {
        obj.children = getMenuByRouter(item.children, access);
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href;
      if (showThisMenuEle(item, access)) res.push(obj);
    }
  });
  return res;
};
/**
 * @param {Array} list 通过路由列表得到菜单列表
 * @returns {Array} 获取到"keepOnlyOne": true
 */
export const getOnlyOneMenuByRouter = (list, access) => {
  let res = [];
  forEach(list, (item) => {
    if (!item.meta || (item.meta && !item.meta.hideInMenu)) {
      if (
        (hasChild(item) || (item.meta && item.meta.showAlways)) &&
        showThisMenuEle(item, access)
      ) {
        getOnlyOneMenuByRouter(item.children, access);
      }
      if (item.meta && item.meta.href) obj.href = item.meta.href;
      if (showThisMenuEle(item, access) && item.meta.keepOnlyOne) {
        res.push(item.name);
      }
    }
  });
  return res;
};

/**
 * @param {Array} routeMetched 当前路由metched
 * @returns {Array}
 */
export const getBreadCrumbList = (routeMetched, homeRoute) => {
  let res = routeMetched
    .filter((item) => {
      return item.meta === undefined || !item.meta.hide;
    })
    .map((item) => {
      let obj = {
        icon: (item.meta && item.meta.icon) || "",
        name: item.name,
        meta: item.meta,
      };
      return obj;
    });
  //隐藏路由显示面包
  res = res.filter((item) => {
    return !item.meta.hideInMenu || item.meta.topical !== "home";
  });
  let value = [...res];
  return value;
};

export const showTitle = (item, vm) =>
  (item.meta && item.meta.title) || item.name;

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers) => {
  let i = -1;
  let len = routers.length;
  let homeRoute = {};
  while (++i < len) {
    let item = routers[i];
    if (item.children && item.children.length) {
      let res = getHomeRoute(item.children);
      if (res.name) return res;
    } else {
      if (item.name === "home") homeRoute = item;
    }
  }
  return homeRoute;
};

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute;
  let newList = [...list];
  if (newList.findIndex((item) => item.name === name) >= 0) return newList;
  else
    newList.push({
      name,
      path,
      meta,
    });
  return newList;
};

/**
 * @param {*} access 用户权限数组，如 ['super_admin', 'admin']
 * @param {*} route 路由列表
 */
const hasAccess = (access, route) => {
  if (route.meta && route.meta.access)
    return hasOneOf(access, route.meta.access);
  else return true;
};

/**
 * 权鉴
 * @param {*} name 即将跳转的路由name
 * @param {*} access 用户权限数组
 * @param {*} routes 路由列表
 * @description 用户是否可跳转到该页
 */
export const canTurnTo = (name, access, routes) => {
  const routePermissionJudge = (list) => {
    return list.some((item) => {
      if (item.children && item.children.length) {
        return routePermissionJudge(item.children);
      } else if (item.name === name) {
        return hasAccess(access, item);
      }
    });
  };

  return routePermissionJudge(routes);
};

/**
 * @param {String} url
 * @description 从URL中解析参数
 */
export const getParams = (url) => {
  const keyValueArr = url.split("?")[1].split("&");
  let paramObj = {};
  keyValueArr.forEach((item) => {
    const keyValue = item.split("=");
    paramObj[keyValue[0]] = keyValue[1];
  });
  return paramObj;
};

export function third_sso_login() {
  const return_url = encodeURIComponent(`${window.location.href}`);
  window.location.href = `${process.env.VUE_APP_SSO_URL}/dex-ums/user/tp/pre-login?returnUrl=${return_url}`;
}

export function goBack(vm, backPath) {
  window.history.length > 2
    ? vm.$router.go(-1)
    : backPath &&
      vm.$router.replace({
        path: backPath,
      });
}
/**
 * 遍历生成菜单树
 * @param {*} list
 * @returns {Array}
 */
export function deepTree(list) {
  const newList = [];
  const map = {};

  list.forEach((e) => (map[e.id] = e));

  list.forEach((e) => {
    const parent = map[e.parentId];

    if (parent) {
      (parent.children || (parent.children = [])).push(e);
    } else {
      newList.push(e);
    }
  });

  const fn = (list) => {
    list.map((e) => {
      if (e.children instanceof Array) {
        e.children = _.orderBy(e.children, "orderNum");

        fn(e.children);
      }
    });
  };

  fn(newList);

  return _.orderBy(newList, "orderNum");
}

export const getMenuList = (list, access) => {
  let res = [];
  forEach(list, (item) => {
    if (item.isShow == "1") {
      // isShow是true的情况下
      let name = item.router;
      if (item.router.includes("/") && item.type == 0) {
        name = item.router.split("/")[1];
      } else if (item.router.includes("/") && item.type == 1) {
        // let li = item.router.lastIndexOf('/')
        name = item.code;
      }
      let obj = {
        icon: item.icon || "",
        name: name,
        meta: {
          title: item.name,
          // "topical": "registration-hall",
          keepAlive: item.keepAlive,
          code: item.code,
        },
        id: item.id,
        parentId: item.parentId,
        orderNum: item.orderNum,
        isShow: item.isShow,
      };
      if (item.children && item.children.length) {
        // 目前只有两级
        obj.children = getMenuList(item.children, access);
      }
      res.push(obj);
    }
  });
  return res;
};

export const otherMenu = () => {
  //  其他
  let menuJsonArr = menuJson.slice(1, 2);
  // let menuJsonArr = menuJson;
  return menuJsonArr;
};

export const faxingzhu = () => {
  // 发行方-主账号
  let menuJsonArr = menuJson.slice(0, menuJson.length - 1);
  menuJsonArr = menuJsonArr.filter((item) => {
    if (item.isShow) {
      return item;
    }
  });
  return menuJsonArr;
};

export const faxingzi = () => {
  // 发行方-子账号
  let menuJsonArr = menuJson.filter((item, index) => {
    if (item.name == "registration-hall") {
      return item;
    }
  });
  return menuJsonArr;
  //
};

export const faxingdailizi = () => {
  // 我是服务商-子账号
  let menuJsonArr = menuJson.filter((item, index) => {
    if (item.name == "registration-hall" || item.name === "my-issuer-list") {
      return item;
    }
  });
  return menuJsonArr;
  //
};

export const faxingdailizhu = () => {
  // 我是服务商-主账号
  let menuJsonArr = menuJson.filter((item, index) => {
    if (item.name == "issuing-qualification-certification-list") {
      return item;
    }
  });
  return menuJsonArr;
};
